<template>
  <div class="anticipos mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Anticipos
          </v-card-title>
          <v-card-subtitle
            >En este apartado podrás registrar y validar todos los anticipos
            realizados por tu clientes para futuras compras en tu centro.
            <br />
            <strong
              >Recuerda que estos anticipos se descuentan por su valor
              total.</strong
            >
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <anticipos-activos
                  :clientes="clientes"
                  :isLoading="isLoading"
                  v-on:loadClientes="loadClientesLite($event)"
                  v-on:updateAnticipos="updateAnticipos($event)"
                />
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <anticipos-inactivos ref="anticiposInactivos" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AnticiposActivos from "../../components/anticipos/AnticiposActivos.vue";
import AnticiposInactivos from "../../components/anticipos/AnticiposInactivos.vue";
export default {
  components: {
    AnticiposActivos,
    AnticiposInactivos,
  },
  data: () => ({
    isLoading: false,
    clientes: [],
  }),
  methods: {
    loadClientesLite(value) {
      this.clientes = [];
      this.isLoading = true;
      const body = {
        route: `/clientes_lite`,
        params: {
          search: value.search,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.clientes = response.data.data;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateAnticipos(value) {
      this.$refs.anticiposInactivos.loadAnticipos();
    },
  },
  created() {
    // this.loadClientesLite("");Z
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
};
</script>
