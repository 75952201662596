<template>
  <div class="anticipos-activos">
    <v-card elevation="0" outlined>
      <v-card-title>
        Anticipos activos: {{ pagination_table.total }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :headers="headers"
          :items="anticipos"
          hide-default-footer
          :page.sync="pagination_table.current_page"
          :items-per-page="pagination_table.per_page"
          @page-count="countPage($event)"
          :loading="loading_t"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <div style="width: 300px !important">
                <v-text-field
                  outlined
                  rounded
                  dense
                  append-icon="mdi-magnify"
                  label="Buscar"
                  class="mt-6"
                  v-model="search_a"
                ></v-text-field>
              </div>
              <v-spacer></v-spacer>
              <v-btn dark color="black" @click="dialog = true">
                Crear
              </v-btn>
              <v-dialog
                v-model="dialog"
                persistent
                scrollable
                max-width="600px"
              >
                <v-card>
                  <v-card-title>
                    Registrar anticipo
                  </v-card-title>
                  <v-card-text>
                    <v-row dense align="center" justitfy="center">
                      <v-col cols="12" md="12" sm="12">
                        <v-card elevation="0" outlined>
                          <v-card-text>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-autocomplete
                                  v-model="id"
                                  v-on="on"
                                  v-bind="attrs"
                                  dense
                                  outlined
                                  rounded
                                  :loading="isLoading"
                                  item-value="id"
                                  :items="lista_clientes"
                                  no-filter
                                  label="Selecciona un cliente"
                                  ref="clientes"
                                  hide-no-data
                                  @update:search-input="customFilterCliente"
                                  @change="detailCliente()"
                                ></v-autocomplete>
                              </template>
                              <span
                                >Filtra tus clientes por nombre o cedula /
                                ID</span
                              >
                            </v-tooltip>
                            <strong class="text-h5 font-weight-medium"
                              >Cliente</strong
                            >
                            <v-row align="center" no-gutters v-if="cliente">
                              <v-col>
                                <strong style="color: #1976d2 !important">
                                  {{ cliente.nombre }}
                                </strong>
                                <br />
                                <strong
                                  >Cc:
                                  <span style="color: #1976d2 !important">{{
                                    cliente.cedula
                                  }}</span>
                                </strong>
                                <br />
                                <strong
                                  >Tel:
                                  <span style="color: #1976d2 !important">{{
                                    cliente.telefono
                                  }}</span>
                                </strong>
                                <br />
                                <strong
                                  >Puntos:
                                  <span style="color: #1976d2 !important">{{
                                    cliente.puntos
                                  }}</span>
                                </strong>
                              </v-col>
                              <v-col align="right">
                                <v-avatar>
                                  <img
                                    :src="
                                      $store.state.centro.route +
                                        'clientes/' +
                                        cliente.foto
                                    "
                                  />
                                </v-avatar>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="6" sm="12">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="anticipo.fecha"
                              outlined
                              rounded
                              dense
                              label="Fecha"
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            scrollable
                            v-model="anticipo.fecha"
                            locale="es-co"
                            color="primary"
                            first-day-of-week="1"
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6" sm="12">
                        <v-text-field
                          type="number"
                          :label="
                            `Valor ${$options.filters.currency(anticipo.valor)}`
                          "
                          v-model.number="anticipo.valor"
                          @change="setValue('valor')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" sm="12">
                        <v-textarea
                          rows="3"
                          no-resize
                          outlined
                          counter
                          auto-grow
                          v-model="anticipo.observacion"
                          label="Observación"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" md="4" sm="12">
                        <v-text-field
                          type="number"
                          :label="
                            `Efectivo ${$options.filters.currency(
                              anticipo.efectivo
                            )}`
                          "
                          v-model.number="anticipo.efectivo"
                          @change="setValue('efectivo')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="12">
                        <v-text-field
                          type="number"
                          :label="
                            `Transferencia ${$options.filters.currency(
                              anticipo.transfer
                            )}`
                          "
                          v-model.number="anticipo.transfer"
                          @change="setValue('transfer')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="12">
                        <v-text-field
                          type="number"
                          :label="
                            `Datafono ${$options.filters.currency(
                              anticipo.datafono
                            )}`
                          "
                          v-model.number="anticipo.datafono"
                          @change="setValue('datafono')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" sm="12">
                        <h3>Total: {{ totalizacion | currency }}</h3>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="cancel()">Cancelar</v-btn>
                    <v-btn
                      color="black"
                      :dark="!loading ? true : false"
                      :disabled="loading"
                      :loading="loading"
                      @click="registar()"
                      >Crear</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.creado="{ item }">
            {{ moment(item.created_at, "YYYY-MM-DD HH:mm:ss").format("lll") }}
          </template>
          <template v-slot:item.fecha="{ item }">
            {{ moment(item.fecha, "YYYY-MM-DD").format("ll") }}
          </template>
          <template v-slot:item.valor="{ item }">
            {{ parseFloat(item.valor) | currency }}
          </template>
          <template v-slot:item.print="{ item }">
            <router-link
              :to="'/print_anticipo/' + item.id"
              target="_blank"
              style="text-decoration: none"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" icon small v-on="on" v-bind="attrs">
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                </template>
                <span>Imprimir</span>
              </v-tooltip>
            </router-link>
          </template>
          <template v-slot:item.anular="{ item }">
            <v-menu :close-on-content-click="true" :nudge-width="200" offset-x>
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      color="error"
                      icon
                      small
                      v-on="{ ...tooltip, ...menu }"
                      v-bind="attrs"
                    >
                      <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                  </template>
                  <span>Anular</span>
                </v-tooltip>
              </template>
              <v-card>
                <v-card-title>Anular</v-card-title>
                <v-card-text>
                  <div style="font-size: 1.3em">
                    ¿Estas seguro de anular el anticipo de
                    {{ item.cliente }} por
                    <strong> {{ item.valor | currency }}? </strong>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary">Cancelar</v-btn>
                  <v-btn dark color="black" @click="anular(item.id)"
                    >Anular</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-menu>
          </template>
        </v-data-table>
        <v-pagination
          v-model="page"
          :length="lengthPagination"
          :total-visible="7"
          @input="changePage"
        ></v-pagination>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "AnticiposActivos",
  props: ["pagination", "isLoading", "clientes"],
  data: () => ({
    menu: false,
    page: 1,
    search: "",
    id: null,
    search_a: "",
    headers: [
      {
        text: "Creado",
        value: "creado",
        align: "start",
        width: 150,
      },
      {
        text: "Fecha",
        value: "fecha",
        width: 110,
      },
      {
        text: "Cliente",
        value: "cliente",
        width: 110,
      },
      {
        text: "ID/Cc",
        value: "cc_cliente",
      },
      {
        text: "Observación",
        value: "observacion",
        sortable: false,
      },
      {
        text: "Valor",
        value: "valor",
      },
      {
        text: "",
        value: "print",
      },
      {
        text: "",
        value: "anular",
      },
    ],
    dialog: false,
    loading: false,
    loading_t: false,
    cliente: null,
    anticipo: {
      valor: 0,
      observacion: "",
      efectivo: 0,
      transfer: 0,
      datafono: 0,
      fecha: moment().format("YYYY-MM-DD"),
    },
    anticipos: [],
    pagination_table: {
      current_page: 1,
      last_page: 0,
      next_page: 1,
      per_page: 50,
      total: 0,
    },
  }),
  methods: {
    loadAnticipos() {
      this.loading_t = true;
      const body = {
        route: `/anticipos?page=${this.pagination_table.current_page}`,
        params: {
          estado: 0,
          search: this.search_a,
          perPage: this.pagination_table.per_page,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.pagination_table.total = response.data.data.total;
            this.pagination_table.last_page = response.data.data.last_page;
            this.pagination_table.from = response.data.data.from;
            this.pagination_table.to = response.data.data.to;
            this.anticipos = response.data.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    countPage(value) {
      this.pagination_table.current_page = value == 0 ? 1 : value;
    },
    changePage(data) {
      this.pagination_table.current_page = data;
      this.loadAnticipos();
    },
    customFilterCliente(item) {
      if (item != null) {
        this.search = item;
      }
    },
    detailCliente() {
      const body = {
        route: "/detalle_cliente",
        params: {
          id: this.id,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.cliente = response.data.data;
          }
        })
        .finally(() => {
          this.$refs.clientes.reset();
          this.search = "";
          // this.$emit("loadClientesLite", this.search);
        });
    },
    registar() {
      this.loading = true;

      if (this.cliente) {
        if (this.anticipo.valor <= this.totalizacion) {
          const body = {
            route: "/registrar_anticipo",
            data: {
              anticipo: Object.assign(
                {
                  cliente_id: this.cliente.id,
                  cliente: this.cliente.nombre,
                  cc_cliente: this.cliente.cedula,
                },
                this.anticipo
              ),
            },
          };
          this.$store
            .dispatch("axios_post", body)
            .then((response) => {
              if (response.data.success) {
                let alert = {
                  alert: true,
                  color: "success",
                  text: response.data.data + response.data.email_status.data,
                };
                this.$store.dispatch("setAlert", alert);
                this.loadAnticipos();
                this.cancel();
              }
            })
            .catch((error) => {
              let alert = {
                alert: true,
                color: "error",
                text: error.response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          let alert = {
            alert: true,
            color: "error",
            text:
              "La distribución en métodos de pago no puede ser menor al valor del anticipo",
          };
          this.$store.dispatch("setAlert", alert);
          this.loading = false;
        }
      } else {
        let alert = {
          alert: true,
          color: "error",
          text: "Debes seleccionar un cliente",
        };
        this.$store.dispatch("setAlert", alert);
        this.loading = false;
      }
    },
    anular(id) {
      const body = {
        route: "anular_anticipo",
        data: {
          id,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            this.$emit("updateAnticipos", true);
            this.loadAnticipos();
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        });
    },
    cancel() {
      this.dialog = false;
      this.anticipo = {
        valor: 0,
        observacion: "",
        efectivo: 0,
        transfer: 0,
        datafono: 0,
        fecha: moment().format("YYYY-MM-DD"),
      };
      this.cliente = null;
    },
    moment(date, format) {
      return moment(date, format);
    },
    setValue(value) {
      this.anticipo[value] =
        this.anticipo[value] == "" ? 0 : this.anticipo[value];
    },
  },
  created() {
    this.loadAnticipos();
  },
  computed: {
    lista_clientes() {
      return this.clientes.map((cliente) => {
        return {
          text: `${cliente.nombre} [${cliente.cedula}]`,
          nombre: cliente.nombre,
          cedula: cliente.cedula,
          id: cliente.id,
        };
      });
    },
    totalizacion() {
      return (
        parseFloat(this.anticipo.efectivo) +
        parseFloat(this.anticipo.transfer) +
        parseFloat(this.anticipo.datafono)
      );
    },
    lengthPagination() {
      function is_decimal(val) {
        if (val % 1 == 0) {
          return true;
        } else {
          return false;
        }
      }
      let number = this.pagination_table.total / this.pagination_table.per_page;

      if (!is_decimal(number)) number = parseInt(number) + 1;

      return number;
    },
  },
  watch: {
    id(val) {
      if (val != undefined || val != null) this.$refs.clientes.reset();
      return;
    },
    search(val) {
      if (val != "")
        this.$emit("loadClientes", {
          search: val,
        });
      return;
    },
    search_a(val) {
      this.loadAnticipos();
      return this.search_a;
    },
  },
};
</script>
