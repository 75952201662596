<template>
  <div class="anticipos-inactivos">
    <v-card elevation="0" outlined>
      <v-card-title>
        Anticipos inactivos: {{ pagination_table.total }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :headers="headers"
          :items="anticipos"
          hide-default-footer
          :page.sync="pagination_table.current_page"
          :items-per-page="pagination_table.per_page"
          @page-count="countPage($event)"
          :loading="loading_t"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <div style="width: 300px !important">
                <v-text-field
                  outlined
                  rounded
                  dense
                  append-icon="mdi-magnify"
                  label="Buscar"
                  class="mt-6"
                  v-model="search_i"
                ></v-text-field>
              </div>
            </v-toolbar>
          </template>
          <template v-slot:item.creado="{ item }">
            {{ moment(item.created_at, "YYYY-MM-DD HH:mm:ss").format("lll") }}
          </template>
          <template v-slot:item.fecha="{ item }">
            {{ moment(item.fecha, "YYYY-MM-DD").format("ll") }}
          </template>
          <template v-slot:item.valor="{ item }">
            {{ parseFloat(item.valor) | currency }}
          </template>
          <template v-slot:item.estado="{ item }">
            <v-chip
              class="my-2"
              :color="item.estado == 1 ? 'primary' : 'error'"
            >
              <span v-if="item.estado == 1">Usado</span>
              <span v-else>Anulado</span>
            </v-chip>
          </template>
          <template v-slot:item.folio="{ item }">
            <span v-if="!item.factura_id">{{ item.folio }}</span>
            <router-link :to="'/detallefactura/' + item.factura_id" v-else>
              {{ item.folio | folio }}
            </router-link>
          </template>
        </v-data-table>
        <v-pagination
          v-model="page"
          :length="lengthPagination"
          :total-visible="7"
          @input="changePage"
        ></v-pagination>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "AnticiposInactivos",
  data: () => ({
    menu: false,
    page: 1,
    search_i: "",
    headers: [
      {
        text: "Creado",
        value: "creado",
        align: "start",
        width: 150,
      },
      {
        text: "Fecha",
        value: "fecha",
        width: 110,
      },
      {
        text: "Cliente",
        value: "cliente",
        width: 110,
      },
      {
        text: "ID/Cc",
        value: "cc_cliente",
      },
      {
        text: "Observación",
        value: "observacion",
        sortable: false,
      },
      {
        text: "Factura",
        value: "folio",
        sortable: false,
      },
      {
        text: "Valor",
        value: "valor",
      },
      {
        text: "Estado",
        value: "estado",
      },
    ],
    loading_t: false,
    anticipos: [],
    pagination_table: {
      current_page: 1,
      last_page: 0,
      next_page: 1,
      per_page: 50,
      total: 0,
    },
  }),
  methods: {
    loadAnticipos() {
      this.loading_t = true;
      const body = {
        route: `/anticipos?page=${this.pagination_table.current_page}`,
        params: {
          estado: 2,
          search: this.search_i,
          perPage: this.pagination_table.per_page,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.pagination_table.total = response.data.data.total;
            this.pagination_table.last_page = response.data.data.last_page;
            this.pagination_table.from = response.data.data.from;
            this.pagination_table.to = response.data.data.to;
            this.anticipos = response.data.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    countPage(value) {
      this.pagination_table.current_page = value == 0 ? 1 : value;
    },
    changePage(data) {
      this.pagination_table.current_page = data;
      this.loadAnticipos();
    },
    moment(date, format) {
      return moment(date, format);
    },
  },
  created() {
    this.loadAnticipos();
  },
  computed: {
    lista_clientes() {
      return this.clientes.map((cliente) => {
        return {
          text: `${cliente.nombre} [${cliente.cedula}]`,
          nombre: cliente.nombre,
          cedula: cliente.cedula,
          id: cliente.id,
        };
      });
    },
    lengthPagination() {
      function is_decimal(val) {
        if (val % 1 == 0) {
          return true;
        } else {
          return false;
        }
      }
      let number = this.pagination_table.total / this.pagination_table.per_page;

      if (!is_decimal(number)) number = parseInt(number) + 1;

      return number;
    },
  },
  watch: {
    search_i(val) {
      this.loadAnticipos();
      return this.search_i;
    },
  },
};
</script>
