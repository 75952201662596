var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"anticipos-inactivos"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v(" Anticipos inactivos: "+_vm._s(_vm.pagination_table.total)+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.anticipos,"hide-default-footer":"","page":_vm.pagination_table.current_page,"items-per-page":_vm.pagination_table.per_page,"loading":_vm.loading_t},on:{"update:page":function($event){return _vm.$set(_vm.pagination_table, "current_page", $event)},"page-count":function($event){return _vm.countPage($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticStyle:{"width":"300px !important"}},[_c('v-text-field',{staticClass:"mt-6",attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search_i),callback:function ($$v) {_vm.search_i=$$v},expression:"search_i"}})],1)])]},proxy:true},{key:"item.creado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.created_at, "YYYY-MM-DD HH:mm:ss").format("lll"))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.fecha, "YYYY-MM-DD").format("ll"))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(parseFloat(item.valor)))+" ")]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"my-2",attrs:{"color":item.estado == 1 ? 'primary' : 'error'}},[(item.estado == 1)?_c('span',[_vm._v("Usado")]):_c('span',[_vm._v("Anulado")])])]}},{key:"item.folio",fn:function(ref){
var item = ref.item;
return [(!item.factura_id)?_c('span',[_vm._v(_vm._s(item.folio))]):_c('router-link',{attrs:{"to":'/detallefactura/' + item.factura_id}},[_vm._v(" "+_vm._s(_vm._f("folio")(item.folio))+" ")])]}}])}),_c('v-pagination',{attrs:{"length":_vm.lengthPagination,"total-visible":7},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }