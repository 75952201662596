var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"anticipos-activos"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v(" Anticipos activos: "+_vm._s(_vm.pagination_table.total)+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.anticipos,"hide-default-footer":"","page":_vm.pagination_table.current_page,"items-per-page":_vm.pagination_table.per_page,"loading":_vm.loading_t},on:{"update:page":function($event){return _vm.$set(_vm.pagination_table, "current_page", $event)},"page-count":function($event){return _vm.countPage($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticStyle:{"width":"300px !important"}},[_c('v-text-field',{staticClass:"mt-6",attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search_a),callback:function ($$v) {_vm.search_a=$$v},expression:"search_a"}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"black"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Crear ")]),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Registrar anticipo ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":"","align":"center","justitfy":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-text',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-autocomplete',_vm._g(_vm._b({ref:"clientes",attrs:{"dense":"","outlined":"","rounded":"","loading":_vm.isLoading,"item-value":"id","items":_vm.lista_clientes,"no-filter":"","label":"Selecciona un cliente","hide-no-data":""},on:{"update:search-input":_vm.customFilterCliente,"change":function($event){return _vm.detailCliente()}},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}},'v-autocomplete',attrs,false),on))]}}])},[_c('span',[_vm._v("Filtra tus clientes por nombre o cedula / ID")])]),_c('strong',{staticClass:"text-h5 font-weight-medium"},[_vm._v("Cliente")]),(_vm.cliente)?_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',[_c('strong',{staticStyle:{"color":"#1976d2 !important"}},[_vm._v(" "+_vm._s(_vm.cliente.nombre)+" ")]),_c('br'),_c('strong',[_vm._v("Cc: "),_c('span',{staticStyle:{"color":"#1976d2 !important"}},[_vm._v(_vm._s(_vm.cliente.cedula))])]),_c('br'),_c('strong',[_vm._v("Tel: "),_c('span',{staticStyle:{"color":"#1976d2 !important"}},[_vm._v(_vm._s(_vm.cliente.telefono))])]),_c('br'),_c('strong',[_vm._v("Puntos: "),_c('span',{staticStyle:{"color":"#1976d2 !important"}},[_vm._v(_vm._s(_vm.cliente.puntos))])])]),_c('v-col',{attrs:{"align":"right"}},[_c('v-avatar',[_c('img',{attrs:{"src":_vm.$store.state.centro.route +
                                      'clientes/' +
                                      _vm.cliente.foto}})])],1)],1):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                      var on = ref.on;
                                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.anticipo.fecha),callback:function ($$v) {_vm.$set(_vm.anticipo, "fecha", $$v)},expression:"anticipo.fecha"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.anticipo.fecha),callback:function ($$v) {_vm.$set(_vm.anticipo, "fecha", $$v)},expression:"anticipo.fecha"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"type":"number","label":("Valor " + (_vm.$options.filters.currency(_vm.anticipo.valor)))},on:{"change":function($event){return _vm.setValue('valor')}},model:{value:(_vm.anticipo.valor),callback:function ($$v) {_vm.$set(_vm.anticipo, "valor", _vm._n($$v))},expression:"anticipo.valor"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"rows":"3","no-resize":"","outlined":"","counter":"","auto-grow":"","label":"Observación"},model:{value:(_vm.anticipo.observacion),callback:function ($$v) {_vm.$set(_vm.anticipo, "observacion", $$v)},expression:"anticipo.observacion"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"type":"number","label":("Efectivo " + (_vm.$options.filters.currency(
                            _vm.anticipo.efectivo
                          )))},on:{"change":function($event){return _vm.setValue('efectivo')}},model:{value:(_vm.anticipo.efectivo),callback:function ($$v) {_vm.$set(_vm.anticipo, "efectivo", _vm._n($$v))},expression:"anticipo.efectivo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"type":"number","label":("Transferencia " + (_vm.$options.filters.currency(
                            _vm.anticipo.transfer
                          )))},on:{"change":function($event){return _vm.setValue('transfer')}},model:{value:(_vm.anticipo.transfer),callback:function ($$v) {_vm.$set(_vm.anticipo, "transfer", _vm._n($$v))},expression:"anticipo.transfer"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"type":"number","label":("Datafono " + (_vm.$options.filters.currency(
                            _vm.anticipo.datafono
                          )))},on:{"change":function($event){return _vm.setValue('datafono')}},model:{value:(_vm.anticipo.datafono),callback:function ($$v) {_vm.$set(_vm.anticipo, "datafono", _vm._n($$v))},expression:"anticipo.datafono"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('h3',[_vm._v("Total: "+_vm._s(_vm._f("currency")(_vm.totalizacion)))])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"black","dark":!_vm.loading ? true : false,"disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){return _vm.registar()}}},[_vm._v("Crear")])],1)],1)],1)],1)]},proxy:true},{key:"item.creado",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.created_at, "YYYY-MM-DD HH:mm:ss").format("lll"))+" ")]}},{key:"item.fecha",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.fecha, "YYYY-MM-DD").format("ll"))+" ")]}},{key:"item.valor",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(parseFloat(item.valor)))+" ")]}},{key:"item.print",fn:function(ref){
                          var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":'/print_anticipo/' + item.id,"target":"_blank"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}],null,true)},[_c('span',[_vm._v("Imprimir")])])],1)]}},{key:"item.anular",fn:function(ref){
                          var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var menu = ref.on;
                          var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":"","small":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v("Anular")])])]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Anular")]),_c('v-card-text',[_c('div',{staticStyle:{"font-size":"1.3em"}},[_vm._v(" ¿Estas seguro de anular el anticipo de "+_vm._s(item.cliente)+" por "),_c('strong',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor))+"? ")])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"dark":"","color":"black"},on:{"click":function($event){return _vm.anular(item.id)}}},[_vm._v("Anular")])],1)],1)],1)]}}])}),_c('v-pagination',{attrs:{"length":_vm.lengthPagination,"total-visible":7},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }